<template>
	<b-button-toolbar style="margin-bottom: 2rem">
		<b-button-group style="flex: 1; display: flex">
			<b-button v-for="(btn, btnIndex) in topBarItems" :key="btnIndex" :variant="activeIndex === btnIndex ? 'primary' : 'outline-primary'" @click="$emit('update:activeIndex', btnIndex)">{{ btn }} </b-button>
		</b-button-group>
	</b-button-toolbar>
</template>

<script>
	export default {
		name: "LeftTopBar",
		props: {
			topBarItems: {
				type: Array,
				required: true,
			},
			activeIndex: {
				type: Number,
				required: true,
			},
		},
	};
</script>

<style scoped></style>

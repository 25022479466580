<template>
  <div class="position-relative">

    <!-- title -->
    <b-row>
      <b-col class="d-flex align-items-center mb-2" cols="2" style="z-index: 2 !important">
        <page-title style="min-width: 18rem"></page-title>
      </b-col>
      <b-col cols="10" style="z-index: 2 !important">
        <slot name="top"></slot>
      </b-col>
    </b-row>

    <!-- content -->
    <b-row>
      <!-- left -->
      <b-col class="position-relative" cols="2" style="z-index: 2 !important">
        <div style="min-width: 20rem">
          <b-button-toolbar style="margin-bottom: 1.5rem">
            <b-button-group style="flex: 1; display: flex">
              <b-button
                  v-b-popover.hover.top="btn.title"
                  v-for="(btn, btnIndex) in topBarItems"
                  :key="btnIndex"
                  :variant="activeIndex === btnIndex ? 'primary' : 'outline-primary'"
                  @click="
									$emit('setIndex', btnIndex);
									$emit('update:activeModel', '');
									$emit('update:floor_selected', '');
								"
              >
                <!--                {{ btn.title }}-->
                <feather-icon :icon="iconType(btn.title)" size="16"/>
              </b-button>
            </b-button-group>
          </b-button-toolbar>
          <div class="rounded" style="max-height: 68vh; overflow-y: scroll">
            <template v-for="(tab, tabIndex) in topBarItems" v-if="activeIndex === tabIndex">
              <slot :name="'left-' + tabIndex"></slot>
            </template>
          </div>
        </div>
      </b-col>

      <!-- right -->
      <b-col cols="10" style="height: calc(100vh - 11.9rem); overflow-y: scroll">
        <template v-for="(tab, tabIndex) in topBarItems" v-if="activeIndex === tabIndex">
          <slot :name="'right-' + tabIndex"></slot>
        </template>
      </b-col>


    </b-row>

    <!-- model -->
    <model-div v-if="(topBarItems[activeIndex].model || activeModel) && activeIndex===0"
               :main_model="topBarItems[activeIndex].model"
               :detail_model="activeModel"></model-div>
    <!--    <div v-show="activeIndex===0" class="position-absolute"-->
    <!--         style="right: 1rem;top: 8rem; max-width: 400px;max-height: calc(100vh - 20rem);overflow-y: scroll;z-index: 2 !important">-->
    <!--      <slot :name="'alarm-info'"></slot>-->
    <!--    </div>-->

    <slot name="background"></slot>
  </div>
</template>

<script>
import PageTitle from "@/views/layout/PageTitle";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import LeftTopBar from "@/views/front/jsf35/subsystems/layout/components/LeftTopBar";
import {watch} from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import {showToast} from "@/libs/utils/showToast";
//若Autodesk未加载成功，等待加载完成后刷新页面
if (typeof Autodesk === 'undefined') {
  let interval_index = 0
  const interval = setInterval(() => {
    if (typeof Autodesk !== 'undefined') {
      clearInterval(interval);
      window.location.reload();
    } else {
      if (interval_index === 0) {
        showToast(null, '提示', '模型依赖未加载成功，请耐心等待。依赖加载完成后，页面将自动刷新！');
      }
      interval_index += 1
      if (interval_index >= 6) {
        interval_index = 0
      }
    }
  }, 1000)

}

export default {
  name: "JsfLayout",
  directives: {
    Ripple,
  },
  components: {
    PageTitle,
    LeftMenu,
    LeftTopBar,
    ModelDiv: () => import("@/views/front/jsf35/subsystems/layout/components/ModelDiv"),
  },
  props: ["activeIndex", "topBarItems", "activeModel", "floor_selected"],
  setup(props, {slots}) {
    const loading_show = window.loading_show;
    watch(
        () => props.activeModel,
        (activeModel) => {
          console.log("layout-activeModel", activeModel);
        }
    );

    //图标处理
    const iconType = (title) => {
      const iconList = {
        '主页': 'Home',
        '设备列表': 'List',
        '数据分析': 'PieChart',
        '模式管理': 'Grid',
        '电梯状态': 'Sliders',
        '报警记录': 'Bell',
        '监控轮巡': 'Video'
      }
      let icon = iconList[title] || 'Home'
      return icon + 'Icon'
    }

    return {
      iconType,
      loading_show
    }
  },
};
</script>

<style lang="scss" scoped></style>

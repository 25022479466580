var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.list && _vm.list.length)?_c('div',{staticClass:"overflow-y-scroll",staticStyle:{"max-height":"calc(100vh - 19rem)"}},[_c('b-list-group',{staticClass:"jsf-list-group",attrs:{"flush":""}},_vm._l((_vm.list),function(item,index){return _c('b-list-group-item',{key:index,staticClass:"py-1 px-2",on:{"click":function($event){return _vm.$emit('select', item)}}},[(item.icon)?_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":item.icon}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.title))])],1)}),1)],1):(_vm.tree && _vm.tree.length)?_c('div',[_c('tree',{attrs:{"nodes":_vm.tree,"setting":_vm.tree_settings},on:{"onClick":function (evt, treeId, treeNode) {
					_vm.$emit('select', treeNode);
				}}})],1):_c('b-tabs',[(_vm.floors && _vm.floors.length)?_c('b-tab',{attrs:{"title":"楼层"}},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"overflow-y-scroll",staticStyle:{"max-height":"calc(100vh - 22rem)"}},[_c('b-list-group',{staticClass:"menu-link",attrs:{"flush":""}},_vm._l((_vm.floors),function(floor,floorIndex){return _c('b-list-group-item',{key:floorIndex,on:{"click":function($event){return _vm.$emit('select', floor)}}},[_vm._v(" "+_vm._s(floor.title)+" ")])}),1)],1)])],1):_vm._e(),(_vm.system)?_c('b-tab',{attrs:{"title":"系统"}},[_vm._t("left-menu-system"),_c('div',{staticClass:"overflow-y-scroll",staticStyle:{"max-height":"calc(100vh - 22rem)"}},[(_vm.system_tree[0] && _vm.system_tree[0].children && _vm.system_tree[0].children.length > 0)?_c('app-collapse',{staticClass:"collapse-menu",attrs:{"accordion":"","type":"margin"}},_vm._l((_vm.system_tree),function(lvl_1,lvl_1_index){return _c('app-collapse-item',{key:'lvl_1_' + lvl_1_index,attrs:{"title":lvl_1.title}},[(lvl_1.children[0].children && lvl_1.children[0].children.length > 0)?[_c('app-collapse',{staticClass:"collapse-menu-lvl2",attrs:{"accordion":""}},_vm._l((lvl_1.children),function(lvl_2,lvl_2_index){return _c('app-collapse-item',{key:'lvl_2_' + lvl_2_index,attrs:{"title":lvl_2.title}},[_c('list-group',{attrs:{"list":lvl_2.children},on:{"select":function (event) {
												_vm.$emit('select', event);
											}}})],1)}),1)]:[_c('list-group',{attrs:{"list":lvl_1.children},on:{"select":function (event) {
										_vm.$emit('select', event);
									}}})]],2)}),1):_c('list-group',{attrs:{"list":_vm.system_tree},on:{"select":function (event) {
							_vm.$emit('select', event);
						}}})],1)],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
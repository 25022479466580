<template>
	<b-list-group class="collapse-menu-link" flush>
		<b-list-group-item v-for="(item, index) in list" :key="index" class="py-1 px-2" @click="item.equip_id ? $store.commit('jsf35/setEquipSelected', item) : $emit('select', item)">
			<feather-icon v-if="item.icon" :icon="item.icon" class="mr-50" />
			<span>{{ item.title }}</span>

			<b-button v-show="item.detail_model_key" class="btn-icon ml-50 position-absolute" size="sm" style="top: 0.5rem; right: 2.6rem; padding: 0.6rem" variant="flat-warning" @click="$emit('select', item)">
				<feather-icon icon="MoreHorizontalIcon" />
			</b-button>

			<b-button v-show="item.equip_id" class="btn-icon ml-50 position-absolute" size="sm" style="top: 0.5rem; right: 0.2rem; padding: 0.6rem" variant="flat-warning" @click="$bvModal.show('model-equip-config')">
				<feather-icon icon="SettingsIcon" />
			</b-button>
		</b-list-group-item>
	</b-list-group>
</template>

<script>
	export default {
		name: "LeftMenuListGroup",
		props: ["list"],
	};
</script>

<style scoped></style>
